<template>
  <div>
    <asideBar />
    <div
      class="main-content flex-fill"
      :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }"
    >
      <div class="container customize-width px-md-5 mt-4">
        <div class="content">
          <div class="content-wrap">
            <div class="content-layout">
              <main>
                <nav class="text-sm text-muted mb-4">
                  <i class="iconfont icon-home mr-2"></i><a
                    class="crumbs"
                    @click="goIndex('/index')"
                  >首页</a><i class="px-2">/</i><a>最新收录</a>
                </nav>
                <div class="block-header my-4">
                  <h4 class="block-title"><i class="io io-faxian"></i>最新收录&nbsp;2205</h4>
                </div>
                <div class="card-grid">
                  <div
                    class="url-card"
                    v-for="item in getDataList"
                    :key="item.id"
                    style="cursor:pointer"
                  >
                    <div class="url-body">
                      <a
                        class="url-content"
                        @click="goIndex('/details?id=' + item.id)"
                      >
                        <div class="url-info">
                          <div class="overflowClip_1 mb-1 mb-md-2">
                            <strong>{{ item.title }}</strong>
                          </div>
                          <p class="overflowClip_2">
                            {{ item.content }}
                          </p>
                        </div>
                      </a>
                      <div class="url-link"></div>
                      <a
                        class="url-details"
                        target="_blank"
                        rel="nofollow"
                      ><i class="iconfont icon-goto"></i></a>
                    </div>
                  </div>
                </div>
                <div class="posts-nav mb-3 mb-md-4">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>

<script>
import { getAllNav } from '@/api/http'
import { API_URL } from '@/utils/request.js'
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
export default {
  components: { asideBar, asideDrawer },
  data () {
    return {
      getDataList: [],
      imageESrc: API_URL,
      page: 1,
      total: 0
    }
  },

  created () {
    this.getList()
  },

  methods: {
    goIndex (val) {
      this.$router.push(val)
    },
    async getList () {
      const res = await getAllNav({ order: 'updatetime', page: this.page })
      this.getDataList = res.data.data.rows
      this.total = res.data.data.total
    },
    prevClick (val) {
      this.page = val
      this.getList()
      console.log('====prevClick=====', val)
    },
    nextClick (val) {
      this.page = val
      this.getList()
      console.log('====nextClick=====', val)
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
      console.log(`当前页---: ${val}`)
    }
  }
}
</script>

<style>
@import url("../css/bootstrap.css");
@import url("../css/style.css");
</style>
